import React from 'react';
import moment from 'moment';
import { Grid, Row, Col } from 'react-bootstrap';
import Alert from '../Alert/Alert';

const secondsToTime = (secs) => {
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
      h: hours,
      m: minutes,
      s: seconds
  };
  return obj;
};

const getPosOrZero = (val) => val >= 0 ? val : 0;

const resetTimer = () => {
  const lm = new Date();
  lm.setTime(lm.getTime() + 1200 * 1000);
  return lm;
};

class Countdown extends React.Component {
  state = {
    secs: 0,
    isExpired: false,
    timeTillDate: resetTimer(),
    params: {
      h: 0,
      m: 0,
      s: 0
    }
  };

  updateState() {
    const { timeTillDate } = this.state;
    const then = moment(timeTillDate);
    const now = moment();
    const secs = then.diff(now, 'seconds');
    const params = secondsToTime(secs);

    const countdown = moment(then - now);
    const days = countdown.format('D');
    const hours = countdown.format('HH');
    const minutes = countdown.format('mm');
    const seconds = countdown.format('ss');
    const isExpired = (params.h < 1 && params.m < 1 && params.s < 1);

    if(isExpired) {
      clearInterval(this.interval);
      this.interval = null;

      // temp hack, clear status polling.
      if(window.pollInterval) {
        clearInterval(window.pollInterval)
      }
    }

    this.setState({ 
      days, 
      hours, 
      minutes, 
      seconds, 
      secs, 
      params,
      isExpired
    });
  }

  componentDidMount() {
    this.updateState();
    this.interval = setInterval(() => {
      this.updateState();
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { params, isExpired } = this.state;
    const { h, m, s } = params;

    return (
      <div>
        {isExpired && 
        <Alert type="error">
          <p>This address is expired. Please restart this transaction.
            We can still receive the payment if you have already sent it.</p>
        </Alert>}
        <Grid className="countdown">
          <Row>
            <Col className="num" xs={4}>{getPosOrZero(h)}</Col>
            <Col className="num" xs={4}>{getPosOrZero(m)}</Col>
            <Col className="num" xs={4}>{getPosOrZero(s)}</Col>
          </Row>
          <Row>
            <Col className="label" xs={4}>hours</Col>
            <Col className="label" xs={4}>minutes</Col>
            <Col className="label" xs={4}>seconds</Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Countdown;

