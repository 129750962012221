import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'react-bootstrap';
import { AD_TYPES } from './PostFormDef';
import * as Views from './PostViewsDef';

const ICON_ID = {
  OK: 'ok',
  QUESTION: 'question-sign',
  CURRENT: 'pencil'
};

const ICON_CLS = {
  SUCCESS: 'label-success',
  WARN: 'label-warn',
  INFO: 'label-primary'
}


class PostSteps extends Component {

  changeView(view, icon) {
    if(icon === ICON_ID.QUESTION) {
      console.log('this view is disabled', view);
      return;
    }

    this.props.setView(view);
  }

  render() {
    const { steps, type, view } = this.props;

    let classRules = !!steps[Views.POST_RULES] ? ICON_CLS.SUCCESS : ICON_CLS.WARN;
    let iconRules = (classRules === ICON_CLS.SUCCESS) ? ICON_ID.OK : ICON_ID.QUESTION;
    if(view === Views.POST_RULES) {
      classRules = ICON_CLS.INFO;
      iconRules = ICON_ID.CURRENT;
    }

    let classType = !!steps[Views.SELECT_TYPE] ? ICON_CLS.SUCCESS : ICON_CLS.WARN;
    let iconType = (classType === ICON_CLS.SUCCESS) ? ICON_ID.OK : ICON_ID.QUESTION;
    if(view === Views.SELECT_TYPE) {
      classType = ICON_CLS.INFO;
      iconType = ICON_ID.CURRENT;
    }

    let classPostForm = !!steps[Views.POST_NEW] ? ICON_CLS.SUCCESS : ICON_CLS.WARN;
    let iconPostForm = (classPostForm === ICON_CLS.SUCCESS) ? ICON_ID.OK : ICON_ID.QUESTION;
    if(view === Views.POST_NEW || view === Views.EDIT_AD) {
      classPostForm = ICON_CLS.INFO;
      iconPostForm = ICON_ID.CURRENT;
    }

    let classPhotos = !!steps[Views.PHOTO_UPLOAD] ? ICON_CLS.SUCCESS : ICON_CLS.WARN;
    let iconPhotos = (classPhotos === ICON_CLS.SUCCESS) ? ICON_ID.OK : ICON_ID.QUESTION;
    if(view === Views.PHOTO_UPLOAD) {
      classPhotos = ICON_CLS.INFO;
      iconPhotos = ICON_ID.CURRENT;
    }

    let classPayment = ICON_CLS.WARN;
    let iconPayment = ICON_ID.QUESTION;
    if(view === Views.PAYMENT) {
      classPayment = ICON_CLS.INFO;
      iconPayment = ICON_ID.CURRENT;
    }

    const sPayment = (type === AD_TYPES.PAID || type === AD_TYPES.FEATURED) ? 'Payment' : 'Finalize Ad';

    const labelRules = <Label className={classRules}><span className={'glyphicon glyphicon-' + iconRules}></span> Rules</Label>;
    const labelType = <Label className={classType}><span className={'glyphicon glyphicon-' + iconType}></span> Type</Label>;
    const labelContent = <Label className={classPostForm}><span className={'glyphicon glyphicon-' + iconPostForm}></span> Ad</Label>;
    const labelPhotos = <Label className={classPhotos}><span className={'glyphicon glyphicon-' + iconPhotos}></span> Photos</Label>
    const labelPayment = <Label className={classPayment}><span className={'glyphicon glyphicon-' + iconPayment}></span>&nbsp;{sPayment}</Label>;

    return (
        <div className="steps">
            <span onClick={() => this.changeView(Views.POST_RULES, iconRules)}>{labelRules}</span>
            <span onClick={() => this.changeView(Views.SELECT_TYPE, iconType)}>{labelType}</span>
            <span onClick={() => this.changeView(Views.POST_NEW, iconPostForm)}>{labelContent}</span>
            <span onClick={() => this.changeView(Views.PHOTO_UPLOAD, iconPhotos)}>{labelPhotos}</span>
            <span onClick={() => this.changeView(Views.PAYMENT, iconPayment)}>{labelPayment}</span>
        </div>
    );
  }
}


PostSteps.propTypes = {
  steps: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired,
  type: PropTypes.string
}

PostSteps.defaultProps = {
  type: ''
}

export default PostSteps;
