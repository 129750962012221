export const PHOTO_DELETE = 'PHOTO_DELETE';
export const PHOTO_PRIMARY = 'PHOTO_PRIMARY';
export const UPLOAD_PHOTOS = 'UPLOAD_PHOTOS'

export const FETCH_REQUEST = 'FETCH_REQUEST';

export const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS';
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS';

export const NEW_POST = 'NEW_POST';

export const FETCH_CITIES = 'FETCH_CITIES';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';

export const NEW_EVENT = 'NEW_EVENT';

export const DELETE_POST = 'DELETE_POST';
export const VALIDATE_AD = 'VALIDATE_AD';

export const VERIFY_PHONE_SEND_SMS = 'VERIFY_PHONE_SEND_SMS';
export const VERIFY_PHONE_VALIDATE = 'VERIFY_PHONE_VALIDATE';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';

export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';

export const CREATE_USER = 'CREATE_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const LOAD_CONFIG = 'LOAD_CONFIG';

export const SET_VIEW = 'SET_VIEW';
export const ENABLE_STEPS = 'ENABLE_STEPS';

export const GENERATE_ADDRESS = 'GENERATE_ADDRESS';
export const CHECK_PAYMENT_STATUS = 'CHECK_PAYMENT_STATUS';
export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';
