import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import React from 'react';

const FieldGroupSelect = ({id, label, help, ...props}) => {
  return (
      <FormGroup controlId={id}>
          <ControlLabel>{label}</ControlLabel>
          <FormControl componentClass="select" {...props}>
              {props.options.map(option => (
                  <option key={option.label} value={option.value}>{option.label}</option>
              ))}
          </FormControl>
      </FormGroup>
  );
}

export default FieldGroupSelect;