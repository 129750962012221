import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Terms extends Component {
    render() {
        return (
            <div className="terms">
                {this.props.children}
            </div>
        )
    }
}

Terms.propTypes = {
    children: PropTypes.any.isRequired
};

export default Terms;