import React, { Component } from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Select from 'react-select';
import { fetchCities, fetchCountries } from '../../actions/postActions';

const getOptionFromValue = (value, options) => {
    return options.find(opt => opt.value === value);
};

class FieldLocation extends Component {
    static defaultProps = {
        cities: {},
        countries: [],
        country: null,
        city: null,
        countryFull: 'United States'
    }

    constructor(props) {
        super(props);
        
        this.state = {
            country: 'US',
            countryFull: null,
            city: null,
            cityFull: null
        };
    }

    componentDidMount() {
        var self = this;
        setTimeout(function() {
            self.props.fetchCountries();
            self.props.fetchCities();

            self.setState({
                city: self.props.city,
                country: self.props.country,
                countryFull: self.props.countryFull
            });
        }, 300);

    }

    onChangeCountry = (item) => {
        console.log('changing country', item)
        const country = _get(item, 'value', '');
        const countryFull = _get(item, 'label', '');
        this.setState({country, countryFull});
    }

    onChangeCity = (item) => {
        if(!item) {
            this.setState({city: ''});
            return;
        }

        let region = null;
        let cityPieces = item.label.split(',');
        let cityFull = cityPieces[0].trim();
        if(cityPieces[1]) {
            region = cityPieces[1].trim();
        }

        let newLocation = {
            city: item.value, 
            cityFull: cityFull,
            country: this.state.country,
            countryFull: this.state.countryFull,
        };

        if(region) {
            newLocation.region = region;
        }

        this.props.onChange(newLocation);
        this.setState({city: item.value});
    }

    getCountryOptions() {
        let options = this.props.countries.map(country => {
            country = country || {};
            country._id = country._id || {};
            let label = country._id.country_full || country._id.country || '';
            if(label === 'US') {
                label = 'United States';
            }
            else if(label === 'CA') {
                label = 'Canada';
            }

            let value = country._id.country || '';

            return { label, value };
        });

        return options;
    }

    getCityOptions() {
        if(!this.state.country || !this.props.cities[this.state.country]) {
            return [];
        }

        let options = this.props.cities[this.state.country].map(city => {
            let label, value;
            
            // label
            label = `${city['city_full']}`;
            if(city['region']) {
                label += `, ${city['region']}`;
            }

            // value
            value = `${city['city']}`;

            return { label, value };
        });

        return options;
    }

    render() {
        const { helpEmail } = this.props
        let countryOptions = this.getCountryOptions();
        let cityOptions = this.getCityOptions();

        return (
            <div>
                <FormGroup>
                    <ControlLabel>Your Country (required)</ControlLabel>
                    <Select
                        onChange={this.onChangeCountry}
                        id="country"
                        name="country"
                        placeholder="Enter and select your country..."
                        autoComplete={'off'}
                        options={countryOptions}
                        value={getOptionFromValue(this.state.country, countryOptions)}
                    />
                    <small><i>Start typing your country to find it faster!</i></small>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Your City (required)</ControlLabel>
                    <Select
                        onChange={this.onChangeCity}
                        id="city"
                        name="city"
                        placeholder="Enter and select your city..."
                        autoComplete={'off'}
                        options={cityOptions}
                        value={getOptionFromValue(this.state.city, cityOptions)}
                    />
                    <small><i>Start typing your city to find it faster! Is your city missing in our city list? Please suggest a city by emailing us at <a href="mailto:{helpEmail}">{helpEmail}</a></i></small>
                </FormGroup>
            </div>
        );
    }
}

FieldLocation.propTypes = {
    fetchCities: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    cities: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    cities: state.location.cities,
    countries: state.location.countries
});

export default connect(mapStateToProps, { fetchCities, fetchCountries })(FieldLocation);
