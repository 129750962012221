import _get from 'lodash/get';
import { FETCH_POSTS_REQUEST, FETCH_POSTS_SUCCESS} from '../actions/types';

const initialState = {
    list: [],
    loading: false
}

export default function postsReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_POSTS_REQUEST: {
            return {
                ...state,
                loading: true
            };
        }
        
        case FETCH_POSTS_SUCCESS: {
            const list = _get(action, ['payload', 'data'], [])
            return {
                ...state,
                list,
                loading: false
            };
        }

        default:
            return state;
    }
}
