import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import _get from 'lodash/get';
import Terms from '../Terms/Terms';
import FormCheckbox from '../Form/Checkbox';
import * as Views from './PostViewsDef';

class Rules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgreeTerms: false
    }
  }

  onContinue = (e) => {
    const { setView, enableSteps } = this.props;
    e.preventDefault();
    window.scrollTo(0, 0);
    setView(Views.SELECT_TYPE);
    enableSteps([Views.POST_RULES]);
  }

  onAgreeTerms = () => {
    this.setState({ isAgreeTerms: true });
  }

  render() {
    const siteId = _get(this.props, ['env', 'SITE_ID'], 'novias');
    const userType = siteId === 'tesc' ? 'transgender': 'female';
    return (<form>
        <h3>Please read our Rules</h3>
        <p>By clicking agree below you agree that you are adhering to rules and  <a 
            href={this.props.baseUrl + '/terms'} target="_blank" rel="noreferrer">terms and conditions</a>. 
            Please be advised that we moderate our ads. Anything that violates our terms is 
            removed immediately and without notice.</p>
        <Terms>
          <ul>
            <li>Poster must be over 21 or of legal age in your region</li>
            <li>Poster is not posting on behalf of someone else without their consent.</li>
            <li>Poster is not engaging in any illegal activity in his/her jurisdiction (eg. pimping, traffic)</li>
            <li>This is a {userType} escort directory only.</li>
            <li>Ads are for entertainment and are not indented to solicit sex.</li>
            <li>Poster is not using ads for anything other than what's posted in the ad (eg. phishing, scams)</li>
            <li>Explicit or offensive media will be removed and may get your entiore post removed.</li>
            <li>Information provided in the ad is accurate. Ads with invalid phone numbers or emails may be 
                removed without notice.</li>
            <li>You agree that we reserve the right to take your ads down anytime.</li>
          </ul>
        </Terms>

        <FormCheckbox label="I have read and understand rules and terms and conditions on this site" 
            checked={this.state.isAgreeTerms} 
            onChange={this.onAgreeTerms} />

        <Button className="btn btn-info btn-wider" onClick={this.onContinue} 
            type="submit" disabled={!this.state.isAgreeTerms}>I Agree</Button> 
    </form>);
  }
}

Rules.propTypes = {
  setView: PropTypes.func.isRequired,
  enableSteps: PropTypes.func.isRequired,
}

export default Rules;