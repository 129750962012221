import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Config from '../../lib/config';
import PostForm from './PostForm';
// import EditForm from './EditForm';

class AdPost extends Component {
    constructor(props) {
      super(props);
      this.state = {
        env: (window.location.hostname === 'localhost') ? 'dev' : 'production'
      };
    }

    render() {
      const { edit } = this.props;

      // if brand new post then start a new one
      if(!edit) {
        return(
          <PostForm baseUrl={`${Config.SITE_URL}`} {...this.props} />
        );
      }

      // render edit form if token is correct and user is created
      // if(edit && postId) {
      //   return(
      //     <EditForm editToken={edit} {...this.props} />
      //   );
      // }
    }
}

export default AdPost;

AdPost.propTypes = {
    edit: PropTypes.string,
    postId: PropTypes.string,
};
