import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import Account from './Account';
import * as Actions from '../../actions/userActions';

const mapStateToProps = (state, ownProps) => {
  const user = _get(state, 'user', { token: false, user: null });
  return {
    lastAction: user.lastAction,
    token: user.token,
    user: user.user,
    error: user.error,
    ...ownProps
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(Actions, dispatch)
  }
};

const AccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);

export default AccountContainer;