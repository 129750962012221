import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import AdPost from './AdPost';
import * as Actions from '../../actions/postActions';
import { isAuthSelector, currentUserSelector } from '../../selectors/UserSelectors';
import { 
  isExpiredSelector, 
  isActiveSelector, 
  expiryFormattedSelector, 
  requiresPaymentSelector,
  addressSelector,
  tokenSelector,
} from '../../selectors/PostSelectors';


const mapStateToProps = (state, ownProps) => {
  const { CONTENT } = state.env;
  const { myPost } = state;
  const isPaid =  !!_get(myPost, ['post', 'paid'], false);

  return {
    content: CONTENT,
    env: {...state.env},
    view: myPost.view,
    steps: myPost.steps,
    post: myPost.post,
    validation: myPost.validation,
    updatedPhotos: myPost.updatedPhotos,
    loading: myPost.loading,
    address: addressSelector(state),
    expiry: expiryFormattedSelector(state),
    isPaid,
    isExpired: isExpiredSelector(state),
    isActive: isActiveSelector(state),
    requiresPayment: requiresPaymentSelector(state),
    isAuthenticated: isAuthSelector(state),
    currentUser: currentUserSelector(state),
    token: tokenSelector(state),
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(Actions, dispatch)
  }
}

const AdPostContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdPost)

export default AdPostContainer