import { connect } from 'react-redux'
import Posts from './Posts'
import { fetchPosts } from '../../actions/postActions';
import { postsSelector, postsLoadingSelector } from '../../selectors/PostSelectors'

const mapStateToProps = (state, ownProps) => {
  return {
    posts: postsSelector(state),
    loading: postsLoadingSelector(state),
    ...ownProps
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPosts: () => dispatch(fetchPosts())
  }
}

const PostsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Posts)

export default PostsContainer