import { connect } from 'react-redux'
import App from './App'
import { loadConfig } from './actions/globalActions';
import { logoutUser } from './actions/userActions';
import { isAuthSelector } from './selectors/UserSelectors';

const mapStateToProps = (state, ownProps) => {
  const { CONTENT, SITE_URL} = state.env
  const isAuth = ownProps.isAuth || isAuthSelector(state)
  return {
    siteUrl: SITE_URL,
    content: { ...CONTENT },
    ...ownProps,
    isAuth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadConfig: () => dispatch(loadConfig()),
    logoutUser: () => dispatch(logoutUser())
  }
}

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default AppContainer