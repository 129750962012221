import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageUploading from 'react-images-uploading';
import { Jumbotron, Button } from 'react-bootstrap';

const PhotoUpload = (props) => {
  const [images, setImages] = useState([]);
  const maxNumber = 30;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    props.onChange(imageList);
  };

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps
      }) => (
        // write your building UI
        <div className="upload__image-wrapper">
          <Jumbotron
            className="drag-area"
            style={isDragging ? { color: "red" } : null}
            onClick={onImageUpload}
            {...dragProps}
          >
            <div>
              Click here to add photos or drag photos to this box! Once you are done
              click <b>Done with Photos</b> to finish uploding.
            </div>
            <br />
            
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image.data_url} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <Button style={{ marginBottom: 5 }} className="btn btn-default" onClick={() => onImageUpdate(index)}>Update</Button>
                  <Button className="btn btn-default" onClick={() => onImageRemove(index)}>Remove</Button>
                </div>
              </div>
            ))}
            
          </Jumbotron>
          {imageList.length > 0 && 
          <div align="right">
            <span className="anchor" onClick={onImageRemoveAll}>Clear temporary photos</span>
          </div>}
        </div>
      )}
    </ImageUploading>
  );
};

PhotoUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
}

export default PhotoUpload;
