import _get from 'lodash/get';
import { 
  CREATE_USER,
  LOGIN_USER,
  SET_CURRENT_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  CHANGE_PASSWORD
} from '../actions/types';

const initialState = {
  token: false,
  email: false,
  lastAction: ''
}

export default function userReducer(state = initialState, action) {
  switch(action.type) {
      case CREATE_USER: {
        const error = _get(action, ['payload', 'error'], false);
        return {
          ...state,
          error
        };
      }

      case LOGIN_USER: {
        const error = _get(action, ['payload', 'error'], false);
        return {
          ...state,
          error
        };
      }

      case RESET_PASSWORD: {
        const error = _get(action, ['payload', 'error'], false);
        return {
          ...state,
          lastAction: RESET_PASSWORD,
          error
        };
      }

      case CHANGE_PASSWORD: {
        const error = _get(action, ['payload', 'error'], false);
        return {
          ...state,
          lastAction: CHANGE_PASSWORD,
          error
        };
      }

      case SET_CURRENT_USER: {
        const token =  _get(action, ['payload', 'token'], false);
        const email = _get(action, ['payload', 'email'], false);
        const userId = _get(action, ['payload', 'userId'], false);
        return {
          ...state,
          email,
          token,
          userId
        };
      }

      case LOGOUT_USER: {
        return {
          ...state,
          token: false,
          user: null
        }
      }

      default:
        return state;
  }
}
