import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import BitcoinReceive from './BitcoinReceive';
import { generateAddress, checkPaymentStatus, updatePaymentStatus } from '../../actions/postActions';
import { isAuthSelector, currentUserSelector } from '../../selectors/UserSelectors';
import { myPostSelector, paymentSelector } from '../../selectors/PostSelectors';


const mapStateToProps = (state, ownProps) => {
  const post = _get(myPostSelector(state), 'post', {});
  const payment = paymentSelector(state);
  return {
    post,
    isAuthenticated: isAuthSelector(state),
    currentUser: currentUserSelector(state),
    payment,
    address: payment.address,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      generateAddress,
      checkPaymentStatus,
      updatePaymentStatus,
    }, dispatch)
  }
}

const BitcoinReceiveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BitcoinReceive)

export default BitcoinReceiveContainer;
