import _get from 'lodash/get'

const formatDate = (str) => {
  const date = new Date(Date.parse(str));
  return date.toLocaleDateString();
};

export const postsSelector = state => _get(state, ['posts', 'list'], []);
export const postsLoadingSelector = state => _get(state, ['posts', 'loading'], false);

export const myPostSelector = state => _get(state, ['myPost'], {});

export const paymentSelector = state => _get(state, ['myPost', 'payment'], {}) || {};
export const tokenSelector = state => _get(state, ['myPost', 'token'], null);
export const addressSelector = state => {
  const payment = paymentSelector(state);
  return payment.address;
};

export const isExpiredSelector = state => {
  const expiryStr = _get(state, ['myPost', 'post', 'expiry'], false);
  if(expiryStr) {
    const expiryDate = new Date(expiryStr);
    return (expiryDate < (new Date()));
  }

  return false;
};

export const expiryFormattedSelector = state => {
  const expiry = _get(state, ['myPost', 'post', 'expiry'], false);
  return expiry
    ? formatDate(expiry)
    : null;
};

export const isActiveSelector = state => !!_get(state, ['myPost', 'post', 'approved'], {});

export const verificationSelector = state => _get(state, ['myPost', 'post', 'verification'], {});

export const requiresPaymentSelector = (state) => {
  const post = _get(state, ['myPost', 'post'], {});
  const isPaid = !!post.paid;
  const isExpired = isExpiredSelector(state);
  const isFree = (post.type === 'free' || post.type === 'free_verified');
  return (isExpired || !isPaid) && !isFree;
};