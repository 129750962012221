import * as types from './types';
import { getHeaders } from './utils';
const Logger = console;

/**
 * Get a post for authenticated user or with edit token
 * @param {string} postId
 * @param {string} editToken
 */
export const fetchPost = (postId, editToken) => (dispatch) => {
    dispatch({
        type: types.FETCH_REQUEST
    });

    editToken = editToken || 'none';
    const resp = fetch(`/api/post/${postId}/${editToken}`, {
        method: 'GET',
        headers: getHeaders({ 'edit-token': editToken })
    })
    .then(res => res.json())
    .then((post) => {
        dispatch({
            type: types.FETCH_POST_SUCCESS,
            payload: post
        });

        return post;
    });

    return resp;
};

/**
 * Get a list of user posts for an authenticated user
 */
export const fetchPosts = () => (dispatch) => {
    dispatch({
        type: types.FETCH_REQUEST
    });

    const resp = fetch('/api/posts', {
        method: 'GET',
        headers: getHeaders()
    })
    .then(res => res.json())
    .then(posts => {
        dispatch({
            type: types.FETCH_POSTS_SUCCESS,
            payload: posts
        });

        return posts;
    });

    return resp;
};

/**
 * Create a new post
 *
 * @param {object} postData 
 */
export const createPost = (postData) => (dispatch) => {
    dispatch({
        type: types.FETCH_REQUEST
    });

    const resp = fetch('/api/posts', {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((post) => {
        dispatch({
            type: types.NEW_POST,
            payload: post
        });

        return post;
    });

    return resp;
};

/**
 * Create an aribtrary event for A/B testing.
 *
 * @param {object} postData 
 */
export const createEvent = (postData) => (dispatch) => {
    const resp = fetch('/api/event', {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((post) => {
        // created a post
        dispatch({
            type: types.NEW_EVENT,
            payload: post
        });

        return post;
    });

    return resp;
};

/**
 * Remove a post
 *
 * @param {string} postId 
 * @param {string} editToken 
 */
export const deletePost = (postId, editToken) => (dispatch) => {
    const resp = fetch('/api/post/delete', {
        method: 'POST',
        headers: getHeaders({ 'edit-token': editToken }),
        body: JSON.stringify({postId})
    })
    .then(res => res.json())
    .then((deleted) => {
        // updated post
        dispatch({
            type: types.DELETE_POST,
            payload: deleted
        });

        return deleted;
    });

    return resp;
};

/**
 * Update a post
 *
 * @param {object} postData 
 * @param {string} editToken 
 */
export const updatePost = (postData, editToken) => (dispatch) => {
    dispatch({
        type: types.FETCH_REQUEST
    });

    const resp = fetch('/api/post', {
        method: 'PATCH',
        headers: getHeaders({ 'edit-token': editToken }),
        body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((post) => {
        // updated post
        dispatch({
            type: types.UPDATE_POST_SUCCESS,
            payload: post
        });

        return post;
    });

    return resp;
};

/**
 * Verify email address by comparing it with generated hash
 *
 * @param {string} email 
 */
export const verifyEmail = (email, token, id) => (dispatch) => {
    dispatch({
        type: types.FETCH_REQUEST
    });

    fetch('/api/verify/email', {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({ email, token, id })
    })
    .then(res => res.json())
    .then((response) => {
        dispatch({
            type: types.VERIFY_EMAIL,
            payload: response
        });
    })
    .catch((err) => {
        Logger.log(err);
    });
}

/**
 * Sends a verification code SMS via twilio
 *
 * @param {string} phone 
 */
export const verifyPhoneSendSms = (phone, id) => (dispatch) => {
    dispatch({
        type: types.FETCH_REQUEST
    });

    fetch('/api/verify/phone', {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({ phone, id })
    })
    .then(res => res.json())
    .then((response) => {
        dispatch({
            type: types.VERIFY_PHONE_SEND_SMS,
            payload: response
        });
    })
    .catch((err) => {
        Logger.log(err);
    });
};

/**
 * Sends a verification code SMS via twilio
 *
 * @param {string} code 
 */
export const verifyPhoneValidateCode = (code, id) => (dispatch) => {
    dispatch({
        type: types.FETCH_REQUEST
    });

    fetch('/api/verify/phone-validate', {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({ code, id })
    })
    .then(res => res.json())
    .then((response) => {
        dispatch({
            type: types.VERIFY_PHONE_VALIDATE,
            payload: response
        });
    })
    .catch((err) => {
        Logger.log(err);
    });
};

/**
 * Automated validates for an ad, eg. backlink or phone number used
 * too frequently (last 24 hours). More rules can be supported.
 *
 * @param {array} fields 
 * @param {*} updated 
 */
export const validateAd = (fields, updated) => (dispatch) => {
    fetch('/validate/ad', {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({fields, updated})
    })
    .then(res => res.json())
    .then((response) => {
        dispatch({
            type: types.VALIDATE_AD,
            payload: response
        });
    })
    .catch((err) => {
        Logger.log(err);
    });
};

/**
 * Remove photo from a post
 *
 * @param {string} photoHash 
 * @param {string} postId 
 * @param {string} editToken 
 */
export const photoDelete = (postId, photoId, editToken) => (dispatch) => {
    fetch('/photo/delete', {
        method: 'POST',
        headers: getHeaders({ 'edit-token': editToken }),
        body: JSON.stringify({postId, photoId})
    })
    .then(res => res.json())
    .then((response) => {
        // updated post
        dispatch({
            type: types.PHOTO_DELETE,
            payload: response
        });
    });
};

/**
 * Make a photo primary
 *
 * @param {string} photoHash 
 * @param {string} postId 
 * @param {string} editToken 
 */
 export const photoMakePrimary = (postId, photoId, editToken) => (dispatch) => {
    fetch('/photo/primary', {
        method: 'POST',
        headers: getHeaders({ 'edit-token': editToken }),
        body: JSON.stringify({postId, photoId})
    })
    .then(res => res.json())
    .then((response) => {
        // updated post
        dispatch({
            type: types.PHOTO_PRIMARY,
            payload: response
        });
    });
}; 

export const generateAddress = (postId, amount, product) => (dispatch) => {
    fetch(`/payment/address/BTC/${postId}/${amount}/${product}`, {
        method: 'GET',
        headers: getHeaders(),
    })
    .then(res => res.json())
    .then((response) => {
        dispatch({
            type: types.GENERATE_ADDRESS,
            payload: response
        });
    });
};

export const checkPaymentStatus = (address, post_id) => (dispatch) => {
    fetch(`/payment/status/BTC/${address}/${post_id}`, {
        method: 'GET',
        headers: getHeaders(),
    })
    .then(res => res.json())
    .then((response) => {
        dispatch({
            type: types.CHECK_PAYMENT_STATUS,
            payload: response
        });
    });
};

export const updatePaymentStatus = (address, post_id, status) => (dispatch) => {
    fetch(`/payment/status/BTC/${address}/${post_id}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ status }),
    })
    .then(res => res.json())
    .then((response) => {
        dispatch({
            type: types.UPDATE_PAYMENT_STATUS,
            payload: response
        });
    });
};

/**
 * Retrieve list of cities
 */
export const fetchCities = () => (dispatch) => {
    fetch('/api/cities')
    .then(res => res.json())
    .then(cities => {
        dispatch({
            type: types.FETCH_CITIES,
            payload: cities
        });
    });
};

/**
 * Retrieve list of countries
 */
export const fetchCountries = () => (dispatch) => {
    fetch('/api/countries')
    .then(res => res.json())
    .then(countries => {
        dispatch({
            type: types.FETCH_COUNTRIES,
            payload: countries
        });
    });
};

/**
 * Upload photos for a post
 */
 export const uploadPhotos = (post_id, photos) => (dispatch) => {
    const formData = new FormData();

    photos.forEach((photo, i) => {
        const { file } = photo;
        formData.append(i, file)
    });

    const headers = getHeaders();
    delete headers['content-type'];
    const res = fetch(`/api/post/${post_id}/photos`, {
        method: 'POST',
        headers,
        body: formData
    })
    .then(res => res.json())
    .then(photos => {
        dispatch({
            type: types.UPLOAD_PHOTOS,
            payload: photos
        });

        return photos;
    });

    return res;
};

export const setView = (view) => (dispatch) => {
    dispatch({
        type: types.SET_VIEW,
        payload: view
    });
};

export const enableSteps = (steps) => (dispatch) => {
    dispatch({
        type: types.ENABLE_STEPS,
        payload: steps
    });
};
