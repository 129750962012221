


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'react-bootstrap';

const styles = {
    label: {
        paddingLeft: '3px',
        fontWeight: 'bold',
        display: 'inline',
        position: 'relative',
        top: '4px'
    }  
}

class FormCheckbox extends Component {
    render() {
        return (
            <div className="checkbox">
                <Checkbox inline className='scale15x'
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled} />
                <label style={styles.label}>{this.props.label}</label>
            </div>
        )
    }
}

FormCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

export default FormCheckbox;

