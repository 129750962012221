import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import FieldGroupSelect from '../FieldGroupSelect/FieldGroupSelect';
import { PAYMENT_TIERS, AD_TYPES, PAYMENT_TIERS_FEATURED } from '../AdPost/PostFormDef';

const getAmountById = (id, tier, type) => {
  const optionsDef = (type === AD_TYPES.FEATURED) ? PAYMENT_TIERS_FEATURED : PAYMENT_TIERS;
  const option = optionsDef[tier].OPTIONS.find(opt => opt.id === id);
  return _get(option, 'amount', 0);
}

const getSelectOptions = (tier) => {
  return tier.OPTIONS.map(option => ({
    label: `${option.label} - € ${option.amount}`,
    value: option.id
  }));
};

const getSiteIdFromHost = (hostname) => {
  return (hostname === 'neogirlz.com' || hostname === 't-escorts.com')
    ? 'neo'
    : 'rye';
}

class PayOptions extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      optionId: props.initOptionId,
      amount: props.initAmount
    }
  }

  getSiteId() {
    const { SITE_NAME } = this.props.env;
    return getSiteIdFromHost(SITE_NAME);
  }

  // Get tier based on the city. Larger cities are more expensive
  // for posting / renewals. If not found return default tier
  getTier() {
    const siteId = this.getSiteId();
    const { post, type } = this.props;
    const tierDef = (type === AD_TYPES.FEATURED) ? PAYMENT_TIERS_FEATURED : PAYMENT_TIERS;
    if(post) {
      const city = this.props.post.city;
      if(city && PAYMENT_TIERS.TIER2.CITIES.includes(city) && siteId === 'rye') {
          return {
            options: getSelectOptions(tierDef.TIER2),
            tier: 'TIER2',
            siteId,
          };
      }
    }

    return {
      options: getSelectOptions(tierDef.TIER1),
      tier: 'TIER1',
      siteId,
    };
  }

  handleChange = (optionId, tier) => {
    const { type, onChange } = this.props;
    const amount = getAmountById(optionId, tier, type)
    this.setState({ optionId, amount });
    onChange({ optionId, amount });
  }
  
  render() {
    const tier = this.getTier();
    const { optionId } = this.state;
    return (
      <div>
        <p>Please select how long you would like to advertise for and complete the payment! </p>
        <p>
          Please note that Bitcoin transaction fees are not cheaper for smaller transactions. 
          If you want the best value then we recommend that you go with 30 or 60 days VIP option!
        </p>

        <FieldGroupSelect
          id="options"
          name="options"
          label="Select Duration"
          help="Select Duration"
          onChange={(e) => this.handleChange(e.target.value, tier.tier)}
          value={optionId} 
          options={tier.options}
        />
      </div>
    );
  }
}

PayOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  env: PropTypes.object,
  initOptionId: PropTypes.string,
  initAmount: PropTypes.string,
};

export default PayOptions;
