import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Navigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import Alert from '../Alert/Alert';
import FieldGroup from '../AdPost/FieldGroup'
import { RESET_PASSWORD, CHANGE_PASSWORD } from '../../actions/types';

class Account extends Component {
    constructor(props) {
      super(props);
      const username = props.username || props.email || '';
      const token = _get(props, 'edit', '');
      const password = _get(props, 'password', '');
      this.state = {
        token,
        username,
        password
      };
    }

    renderLoggedIn() {
      return <Navigate to="/posts" />
    }

    renderResetPassword() {
      const { changePassword, error, lastAction } = this.props;
      const { username, password, token } = this.state;
      const onSubmit = (e) => {
        e.preventDefault();
        changePassword(username, password, token);
      };
      const onChangePassword = (e) => { this.setState({password: e.target.value }) };

      if(!token || !username) {
        return (
          <Alert type="error">
            <p>Oops... There is a problem with the link.</p>
          </Alert>
        );
      }

      if(lastAction === CHANGE_PASSWORD && !error) {
        return (
          <Alert type="success">
              <div>
                <p>Your password has been successfully reset.</p>
                <p>You may now log in using your new username and password.</p>
              </div>
          </Alert>
        );
      }

      return(
        <form onSubmit={onSubmit.bind(this)}>
          <Row>
            <Col sm={8} xs={12}>
              <h1>Reset Password</h1>
              <p>Please enter a new strong password.</p>

              {error && <p className="red">{error}</p>}
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={12}>
              <FieldGroup
                  id="inputPassword"
                  name="password"
                  type="password"
                  label="New Password"
                  placeholder="Your new password"
                  help="Enter your new password. Please make sure to type a strong password."
                  onChange={onChangePassword}
                  value={this.state.password} 
                  required={'required'}
                />
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={12}>
              <Button className="btn btn-primary btn-lg" type="submit">Reset Password</Button>
            </Col>
          </Row>
        </form>
      );
    }

    renderForgotPassword() {
      const { resetPassword, error, lastAction } = this.props;
      const { username } = this.state;
      const onSubmit = (e) => {
        e.preventDefault();
        resetPassword(username);
      };
      const onChangeUsername = (e) => { this.setState({username: e.target.value }) };

      if(lastAction === RESET_PASSWORD && !error) {
        return (
          <Alert type="success">
              <div>
                <p>A password reset email will be sent to you shortly. </p>
                <p>Please follow instructions in there to reset your password.</p>
              </div>
          </Alert>
        );
      }

      return(
        <form onSubmit={onSubmit.bind(this)}>
          <Row>
            <Col sm={8} xs={12}>
              <h1>Forgot Your Password</h1>
              <p>Please enter the email that you used when you posted an ad. 
                Give it a minute after and a password reset email will be in your inbox.</p>

              {error && <p className="red">{error}</p>}
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={12}>
              <FieldGroup
                id="inputUsername"
                name="username"
                type="text"
                label="Email"
                placeholder="Your email"
                help="Enter your email you used to post an ad. An email will be sent to this address."
                onChange={onChangeUsername}
                value={this.state.username} 
                required={'required'}
                onValidate={() => {}}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={12}>
              <Button className="btn btn-primary btn-lg" type="submit">Reset Password</Button>
            </Col>
          </Row>
        </form>
      );
    }

    renderLogin() {
      const { loginUser, error } = this.props;
      const onChangePassword = (e) => { this.setState({password: e.target.value }) };
      const onChangeUsername = (e) => { this.setState({username: e.target.value }) };
      const { username, password } = this.state;
      const onSubmit = (e) => {
        e.preventDefault();
        loginUser(username, password);
      };
      return(
        <form onSubmit={onSubmit.bind(this)}>
          <Row>
            <Col sm={8} xs={12}>
              <h1>Login</h1>
              <p>You registered after posting your first ad! To edit your ads you can login 
                using email as your username, and your password.</p>

              {error && <p className="red">{error}</p>}
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={12}>
              <FieldGroup
                  id="inputUsername"
                  name="username"
                  type="text"
                  label="Email"
                  placeholder="Your email address"
                  help="Your email is your username"
                  onChange={onChangeUsername}
                  value={this.state.username} 
                  required={'required'}
                  onValidate={this.onValidate}
                />
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={12}>
              <FieldGroup
                  id="inputPassword"
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Your password"
                  help="Choose a strong password."
                  onChange={onChangePassword}
                  value={this.state.password} 
                  required={'required'}
                  onValidate={this.onValidate}
                />
            </Col>
          </Row>
          {/* <Row>
            <Col sm={8} xs={12}>
                Can't remember your username. <LinkContainer to="/forgotpassword">
                  <a href="/forgotpassword">Click here</a>
                </LinkContainer> to reset your password.
            </Col>
          </Row> */}
          <Row>
            <Col sm={8} xs={12} className="mar-top-md">
              <Button className="btn btn-primary btn-lg" type="submit">Login</Button>
            </Col>
          </Row>
        </form>
      )
    }

    renderRegister() {
      const { email, edit, registerUser, error } = this.props;
      const { password } = this.state;
      const onChangePassword = (e) => { this.setState({password: e.target.value }) };
      const onSubmit = (e) => {
        e.preventDefault();
        registerUser(email, password, edit);
      };
  
      return (
        <form onSubmit={onSubmit}>
          <h1>Choose a username...</h1>
          <p>
            You posted an ad but you don't have an account yet. As an account holder you will be able to
            edit or delete your ads. To respect your privacy we only need your email and a password!
          </p>
          <p>
            Your username is <b>{email}</b>.<br />
            Now please select a password so that you can login later.
          </p>

          {error && <p className="red">{error}</p>}
  
          <Row>
            <Col xs={12} sm={6}>
              <FieldGroup
                  id="inputPassword"
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Your password"
                  help="Choose a strong password."
                  onChange={onChangePassword}
                  value={this.state.password} 
                  required={'required'}
                  onValidate={this.onValidate}
                  // clss={this.state.validClss.email}
                />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <Button className="btn btn-primary btn-lg" type="submit">Create Account</Button>
            </Col>
          </Row>
        </form>
      );
    }

    render() {
      const { type, email, edit, token } = this.props;
      if(token) {
        return this.renderLoggedIn();
      }

      if(type === 'register' && email && edit) {
        return this.renderRegister();
      }

      if(type === 'forgotpassword') {
        return this.renderForgotPassword()
      }

      if(type === 'resetpassword') {
        return this.renderResetPassword();
      }

      return this.renderLogin();
    }
}

Account.propTypes = {
  type: PropTypes.string.isRequired,
  changePassword: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  email: PropTypes.string,
  edit: PropTypes.string, // token
  user: PropTypes.object
};

export default Account;