import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import ReactHtmlParser from 'react-html-parser';
import { Well, Table } from 'react-bootstrap';

class AdPreview extends Component {
    render() {
        let photos = this.props.photos || [];
        let stats = [
            {label: 'City', value: this.props.city_full || 'unknown'},
            {label: 'Age', value: this.props.age || 'not set'},
            {label: 'Ethnicity', value: this.props.ethnicity || 'not set'},
            {label: 'Phone', value: this.props.phone || 'not set'},
            {label: 'Email', value: this.props.email || 'not set'},
            {label: 'Business', value: this.props.business || 'not set'},
            {label: 'Incalls', value: this.props.incalls || 'not set'},
            {label: 'Outcalls', value: this.props.outcalls || 'not set'}
        ];

        return (
            <div className="ad-preview">
                <p><b>Here is a sneak preview of your ad.</b></p>
                <p style={{ fontWeight: 'bold', color: '#616463'}}>Post ID: {this.props.post_id}</p>
                <Well bsSize="small">
                    <h3>{this.props.title}</h3>
                    {/* <p>{ ReactHtmlParser(this.props.description) }</p> */}
                    <Table striped bordered condensed >
                        <tbody>
                            {stats.map((stat, index) =>{
                                return <tr key={stat.label}>
                                    <td>{stat.label}</td>
                                    <td>{stat.value}</td>
                                </tr>;
                            })}
                        </tbody>
                    </Table>    

                    <div className="photos">
                        {photos.map((photo) => {
                            return <p key={photo.url}>
                                <img className="img-thumbnail" alt="" width="250" src={photo.url} />
                            </p>
                        })} 
                    </div>   
                </Well>
            </div>
        );
    }
}

AdPreview.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    age: PropTypes.any,
    city_full: PropTypes.string,
    photos: PropTypes.array
};

export default AdPreview;
