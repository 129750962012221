import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Alert extends Component {
  render() {
    const { type } = this.props;
    const typeClss = (type === 'error') ? 'danger' : 'success';
    return (
      <div className="ad-complete">
        <div className={`alert alert-${typeClss} alert-medium`}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
};

export default Alert;

