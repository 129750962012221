import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import AdPhotos from './AdPhotos';
import { photoDelete, photoMakePrimary } from '../../actions/postActions';
import { isAuthSelector, currentUserSelector } from '../../selectors/UserSelectors';
import { myPostSelector } from '../../selectors/PostSelectors';


const mapStateToProps = (state, ownProps) => {
  const post = _get(myPostSelector(state), 'post', {});
  return {
    post,
    isAuthenticated: isAuthSelector(state),
    currentUser: currentUserSelector(state),
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      photoDelete,
      photoMakePrimary
    }, dispatch)
  }
}

const AdPhotosContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdPhotos)

export default AdPhotosContainer;
