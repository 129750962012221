import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import './index.css';
import store from './store';
import { setCurrentUser } from './actions/userActions';
import App from './AppContainer';
import Posts from './components/Posts/PostsContainer';
import AdPost  from './components/AdPost/AdPostContainer';
import Account from './components/Account/AccountContainer';
import VerifyAd from './components/VerifyAd/VerifyAdContainer';

function getUrlParameter(name) {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var query = window.location.search || '';
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(query);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const getPostProps = () => ({
    postId: getUrlParameter('id'),
    edit: getUrlParameter('edit'),
    renew: !!getUrlParameter('renew')
});

const getRegisterProps = () => ({
    email: getUrlParameter('email'),
    edit: getUrlParameter('edit'),
});

const getVerifyAdProps = () => ({
    email: getUrlParameter('email'),
    token: getUrlParameter('token'),
    id: getUrlParameter('id')
});

let isAuth = false;
if(localStorage.session) {
    const { session, email, userId } = localStorage;
    isAuth = true;
    store.dispatch(setCurrentUser(session, email, userId));
}

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App isAuth={isAuth}>
                <Routes>
                    <Route exact path='/' element={<AdPost {...getPostProps()} />}/>
                    <Route path='/posts' element={<Posts />} />
                    <Route path='/resetpassword' element={<Account type="resetpassword" {...getRegisterProps()} />} />
                    <Route path='/forgotpassword' element={<Account type="forgotpassword" />} />
                    <Route path='/login' element={<Account type="login" />} />
                    <Route path='/register' element={<Account type="register" {...getRegisterProps()} />} />
                    <Route path='/activate' element={<VerifyAd {...getVerifyAdProps()} />} />
                </Routes>
            </App>
        </Router>
    </Provider>, document.getElementById('root')
);
