import { combineReducers } from 'redux';
import postReducer from './postReducer';
import postsReducer from './postsReducer';
import locationReducer from './locationReducer'
import userReducer from './userReducer'
import envReducer from './envReducer'

export default combineReducers({
    user: userReducer,
    myPost: postReducer,
    posts: postsReducer,
    location: locationReducer,
    env: envReducer
});
