

class Config {
  constructor() {
    this.hostname = window.location.hostname;
  }

  getDev() {
    return {
      SITE_ID: 'novias',
      SITE_PROTOCOL: 'http://',
      SITE_URL: 'http://localhost:3000',
      SITE_NAME: 'houses.com', // this should have domain in it!
      SITE_DESCRIPTION: 'Free Ads Directory',
      SITE_EMAIL: 'info@5houses.com',
      ENV: 'development',
      CONTENT: {
        heading: {
          part1: 'My',
          part2: 'Houses',
          part3: 'Network',
        }
      }
    }
  }

  getProductionNeo() {
    return {
      SITE_ID: 'neo',
      SITE_PROTOCOL: 'https://',
      SITE_URL: 'https://neogirlz.com',
      SITE_NAME: 'neogirlz.com', 
      SITE_DESCRIPTION: 'Free Tansgender Escort Ads Directory',
      SITE_EMAIL: 'info@neogirlz.com',
      ENV: 'production',
      CONTENT: {
        heading: {
          part1: 'Neo',
          part2: 'Girlz',
          part3: '.com',
        }
      }
    }
  }

  getProductionTesc() {
    return {
      SITE_ID: 'tesc',
      SITE_PROTOCOL: 'https://',
      SITE_URL: 'https://t-escorts.com',
      SITE_NAME: 't-escorts.com', 
      SITE_DESCRIPTION: 'Free Tansgender Escort Ads Directory',
      SITE_EMAIL: 'info@t-escorts.com',
      ENV: 'production',
      CONTENT: {
        heading: {
          part1: 'T-',
          part2: 'Escorts',
          part3: '.com',
        }
      }
    }
  }

  getProductionRye() {
    return {
      SITE_ID: 'novias',
      SITE_PROTOCOL: 'https://',
      SITE_URL: 'https://escortz.cc',
      SITE_NAME: 'Escortz.cc',
      SITE_DESCRIPTION: 'Free Escort Ads Directory',
      SITE_EMAIL: 'info@escortz.cc',
      ENV: 'production',
      CONTENT: {
        host: 'escortz.cc',
        heading: {
          part1: '',
          part2: 'Escortz',
          part3: '.cc',
        }
      }
    }
  }

  getProductionNetwork() {
    return {
      SITE_ID: 'network',
      SITE_PROTOCOL: 'https://',
      SITE_URL: 'https://myescort.network',
      SITE_NAME: 'MyEscort.Network',
      SITE_DESCRIPTION: 'Free Escort Ads Directory',
      SITE_EMAIL: 'info@myescort.network',
      ENV: 'production',
      CONTENT: {
        host: 'myescort.network',
        heading: {
          part1: 'My',
          part2: 'Escort',
          part3: 'Network',
        }
      }
    }
  }

  getConfig() {
    switch(this.hostname) {
      case 'localhost': 
        return this.getDev()
      case 'post.escortz.cc':
          return this.getProductionRye();
      case 'post.t-escorts.com':
        return this.getProductionTesc()
      case 'post.myescort.network':
        return this.getProductionNetwork()
      default:
        return this.getProductionNetwork()
    }
  }
}

export default Config