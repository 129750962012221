import _get from 'lodash/get'

export const isAuthSelector = state => !!_get(state, ['user', 'token'], false);
export const currentUserSelector = state => {
  const token = _get(state, ['user', 'token'], false);
  const email = _get(state, ['user', 'email'], false);
  const userId = _get(state, ['user', 'userId'], false);
  const isAuth = !!token && email;
  return isAuth
    ? { token, email, userId }
    : {};
}