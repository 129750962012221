import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get';
import VerifyAd from './VerifyAd';
import * as Actions from '../../actions/postActions'
import { verificationSelector, myPostSelector } from '../../selectors/PostSelectors';

const mapStateToProps = (state, ownProps) => {
  const myPost = myPostSelector(state);
  const verification = verificationSelector(state);
  return {
    loading: myPost.loading,
    validEmail: !!verification.verified_email,
    validPhone: !!verification.verified_phone,
    phoneCodeSent: !!verification.phone_verify,
    swaggr: verification.swaggr,
    error: verification.error,
    phone: _get(myPost, ['post', 'phone'], null),
    ...ownProps
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(Actions, dispatch)
  }
};

const VerifyAdContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyAd);

export default VerifyAdContainer;