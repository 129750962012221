import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, FormControl } from 'react-bootstrap';

class FieldGroupSelect extends Component {
    render() {
    const { id, label, options } = this.props;
      return (
          <FormGroup controlId={id}>
              <ControlLabel>{label}</ControlLabel>
              <FormControl componentClass="select" {...this.props}>
                {options.map(option => (
                    <option key={option.label} value={option.value}>{option.label}</option>
                ))}
              </FormControl>
          </FormGroup>
      );
    }
};

FieldGroupSelect.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    help: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired
}

export default FieldGroupSelect;
