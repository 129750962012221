import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import PayOptions from '../PayForm/PayOptions';
import Spinner from '../Spinner/Spinner';
import BitcoinReceive from '../BitcoinReceive';
import { PAYMENT_TIERS, PAYMENT_IDS } from '../AdPost/PostFormDef';

const getHeading = (heading, address, action) => {
  if(address) {
    return 'Please complete the payment';
  }

  if(heading) {
    return heading;
  }

  return `Last step before ${action}ing your ad`;
}

class PayForm extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      optionId: PAYMENT_IDS.DAYS_60,
      amount: PAYMENT_TIERS.TIER1.OPTIONS[0].amount
    }
  }

  handleOptionChange({ optionId, amount }) {
    this.setState({ optionId, amount });
  }

  render() {
    const { post, isRenew, isVisible, type, address } = this.props;
    const { amount, optionId } = this.state;
    const postId = _get(post, 'post_id', false);
    const action = isRenew ? 'renew' : 'publish';

    if(!postId) {
      return (
        <div>
          <h3>Oops... There was a problem!</h3>
          <p>We could not retrieve this ad in our database.<br />
            You may post a new ad if you like.
          </p>
          <p>
            We apologize for the inconvenience!
          </p>
        </div>
      )
    }

    if(!isVisible) {
      return <Spinner />
    }

    return (
        <div>
            <h3>{getHeading(this.props.heading, address, action)}</h3>

            {!address && <PayOptions 
              type={type} 
              onChange={(params) => this.handleOptionChange(params)} 
              env={this.props.env}
              initOptionId={this.state.optionId}
              initAmount={this.state.amount}
            />}

            <BitcoinReceive amount={parseFloat(amount)} postId={postId} product={optionId} />
        </div>
    );
  }
}

PayForm.propTypes = {
  type: PropTypes.string.isRequired,
  env: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  isRenew: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  address: PropTypes.string,
};

PayForm.defaultProps = {
  address: null,
}

export default PayForm;
