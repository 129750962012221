export const STAGES = {
    POST_RULES: 'POST_RULES',
    SELECT_TYPE: 'SELECT_TYPE',
    POST_FORM: 'POST_FORM',
    VERIFY_NUMBER: 'VERIFY_NUMBER',
    PAYMENT: 'PAYMENT'
};

export const AD_TYPES = {
    FREE: 'free',
    FREE_VERIFIED: 'free_verified',
    PAID: 'paid',
    FEATURED: 'premium'
};

// const SAMPLE_STATE = {
//     country: 'US',
//     city: 'seattle',
//     backlink: '',
//     name: 'johnny',
//     email: 'johnny@gmail.com', 
//     phone: '2223332221', 
//     age: '21', 
//     ethnicity: 'Asian', 
//     title: 'This is the title', 
//     description: 'This is description',
//     outcalls: 'any',
//     incalls: 'private',
//     business: 'independent',
//     languages: '',
//     photos: []
// };

export const INITIAL_STATE = { 
    country: 'US',
    city: '',
    backlink: '',
    name: '',
    email: '', 
    phone: '', 
    age: '', 
    ethnicity: '', 
    title: '', 
    description: '',
    outcalls: '',
    incalls: '',
    business: '',
    languages: '',
    photos: []
};

export const ETHNICITY_OPTIONS = [
    {value: '', label: '- Select Ethnicity -'},
    {value: 'Asian', label: 'Asian'},
    {value: 'ABC/CBC Asian', label: 'ABC/CBC Asian'},
    {value: 'African American', label: 'African American'},
    {value: 'Caucasian', label: 'Caucasian'},
    {value: 'European', label: 'European'},
    {value: 'Indian', label: 'Indian'},
    {value: 'Latino/Hispanic', label: 'Latino/Hispanic'},
    {value: 'Middle Eastern', label: 'Middle Eastern'},
    {value: 'Mixed', label: 'Mixed'},
    {value: 'Native', label: 'Native'},
    {value: 'Other Ethnicity', label: 'Other Ethnicity'},
];

export const BUSINESS_OPTIONS = [
    {value: '', label: '- Prefer not to say -'},
    {value: 'independent', label: 'Independent Escort'},
    {value: 'agency', label: 'Escort Agency'},
    {value: 'massage', label: 'Massage'}
];

export const INCALL_OPTIONS = [
    {value: '', label: '- Prefer not to say -'},
    {value: 'no', label: 'No incalls'},
    {value: 'private', label: 'Private Residence'},
    {value: 'upscale', label: 'Upscale Condo'},
    {value: 'hotel', label: 'Hotel'}
];

export const OUTCALLS_OPTIONS = [
    {value: '', label: '- Pefer not to say -'},
    {value: 'no', label: 'No outcalls'},
    {value: 'any', label: 'Hotel or residence'},
    {value: 'hotel', label: 'Hotels only'}
];

export const SITES = {
    TIER1: 'TIER1',
    TIER2: 'TIER2'
}

export const PAYMENT_IDS = {
    DAYS_60: 'DAYS_60',
    DAYS_30: 'DAYS_30',
    DAYS_15: 'DAYS_15',
    DAYS_4: 'DAYS_4'
};

export const PAYMENT_TIERS_FEATURED = {
    TIER1: {
        OPTIONS: [
            {
                'id': PAYMENT_IDS.DAYS_30,
                'amount': '60.00',
                'label': '30 days'
            },
            {
                'id': PAYMENT_IDS.DAYS_15,
                'amount': '30.00',
                'label': '15 days'
            },
            {
                'id': PAYMENT_IDS.DAYS_4,
                'amount': '10.00',
                'label': '4 days'
            }
        ],
        DEFAULT: true
    },
    TIER2: {
        OPTIONS: [
            {
                'id': PAYMENT_IDS.DAYS_30,
                'amount': '135.00',
                'label': '30 days'
            },
            {
                'id': PAYMENT_IDS.DAYS_15,
                'amount': '70.00',
                'label': '15 days'
            },
            {
                'id': PAYMENT_IDS.DAYS_4,
                'amount': '25.00',
                'label': '4 days'
            }
        ]
    }
};

export const PAYMENT_TIERS = {
    TIER1: {
        OPTIONS: [
            {
                'id': PAYMENT_IDS.DAYS_60,
                'amount': '15.00',
                'label': '60 days VIP ad'
            },
            {
                'id': PAYMENT_IDS.DAYS_30,
                'amount': '7.50',
                'label': '30 days VIP ad'
            },
            {
                'id': PAYMENT_IDS.DAYS_15,
                'amount': '4.00',
                'label': '15 days VIP ad'
            }
        ],
        DEFAULT: true
    },
    TIER2: {
        OPTIONS: [
            {
                'id': PAYMENT_IDS.DAYS_60,
                'amount': '15.00',
                'label': '60 days VIP ad'
            },
            {
                'id': PAYMENT_IDS.DAYS_30,
                'amount': '7.50',
                'label': '30 days VIP ad'
            },
            {
                'id': PAYMENT_IDS.DAYS_15,
                'amount': '4.00',
                'label': '15 days VIP ad'
            }
        ],
        CITIES: ['odessa', 'atlanta', 'losangeles', 'sarasota', 'boston', 'sanantonio', 'inlandempire', 'killeen', 
            'killeen', 'tucson', 'sandiego', 'denver', 'houston', 'portland', 'orlando', 'austin', 'dallas', 'phoenix',
            'lasvegas', 'ftlauderdale', 'sanjose', 'orangecounty', 'seattle', 'san-francisco', 'sandiego']
    }
};

export const isPaidOption = (option) => {
    return (option === AD_TYPES.PAID || option === AD_TYPES.FEATURED);
};

export const isFreeOption = (option) => {
    return (option === AD_TYPES.FREE || option === AD_TYPES.FREE_VERIFIED);
}