// Step views
export const POST_RULES = 'POST_RULES';     // 1
export const SELECT_TYPE = 'SELECT_TYPE';   // 2
export const POST_NEW = 'POST_NEW';         // 3
export const EDIT_AD = 'EDIT_AD';           // 4
export const PHOTO_UPLOAD = 'PHOTO_UPLOAD'; // 5
export const PAYMENT = 'PAYMENT';           // 6

// Intermediary views
export const POST_SUCCESS = 'POST_SUCCESS';
export const POST_ERROR = 'POST_ERROR';

export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_ERROR = 'UPDATE_ERROR';

export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_ERROR = 'DELETE_ERROR';
export const DELETE_CONFIRM = 'DELETE_CONFIRM';