import { 
  LOAD_CONFIG
} from '../actions/types';
import Config from '../lib/config'

const initialState = {}

export default function envReducer(state = initialState, action) {
  switch(action.type) {
      case LOAD_CONFIG:
        const conf = new Config()
        const env = conf.getConfig()
        return {
          ...state,
          ...env
        };

      default:
        return state;
  }
}
