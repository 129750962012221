import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import FieldGroup from '../AdPost/FieldGroup'
import Spinner from '../Spinner/Spinner';

class VerifyAd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smsCode: '',
      error: null,
    };
  }

  componentDidMount() {
    const { email, token, id, verifyEmail } = this.props;
    verifyEmail(email, token, id);
  }

  componentWillReceiveProps(nextProps) {
    const { verifyPhoneSendSms } = this.props;
    const { phone, phoneCodeSent, id, validEmail, error } = nextProps;
    if (phone && phone !== this.props.phone && !phoneCodeSent && validEmail) {
        verifyPhoneSendSms(phone, id);
    }

    if(error) {
      this.setState({ error });
    }
  }

  renderError() {
    return (
      <div>
        <h1>Oops. Problem!</h1>
        <p className="red">We could not validate your email address!</p>
      </div>
    );
  }

  resendSMSCode(e) {
    e.preventDefault();
    const { verifyPhoneSendSms, phone, id } = this.props;
    if (phone && id) {
        verifyPhoneSendSms(phone, id);
    }
    
  }

  renderComplete() {
    return (
      <div>
        <h1>You are almost done!</h1>
        <div className="alert alert-success alert-medium">
          <p>
            We are one step closer to getting you verified. The last step is to verify that you
            are a real person and the person in the ad. Verified ads get the best visibility and
            are more likely to be approved.
          </p>
        </div>


        <h3>Upload a Photo with a Sign</h3>
        <p>If you haven't already done so, you may upload at least one photo of you
          holding a sign with your <b>stage name</b> or <b>today's date</b>. Some of these
          ads may take up to 48 hours before they are published.
        </p>

      </div>
    );
  }

  render() {
    const {
      loading,
      verifyPhoneValidateCode,
      validEmail,
      validPhone,
      phone,
      id
    } = this.props;
    const { smsCode, error } = this.state;

    const onChangeCode = (e) => {
      this.setState({ smsCode: e.target.value, error: null })
    };

    const onSubmit = (e) => {
      e.preventDefault();
      verifyPhoneValidateCode(smsCode, id);
    };

    if(loading) {
      return <Spinner />;
    }

    if(validPhone) {
      return this.renderComplete();
    }

    if((!validEmail && !loading)) {
      return this.renderError();
    }

    return validEmail && (
      <form onSubmit={onSubmit}>
        <Row>
          <Col xs={12}>
            <h1>Your email is verified!</h1>
            <p>
              Great news! Your email is verified. There is one more step left before your ad is published.
              It is time to validate your phone number. We sent you a text message to <strong>{phone}</strong>.
            </p>
            
            <FieldGroup
              id="inputPhoneCode"
              name="code"
              type="text"
              label="Enter Verification Code"
              placeholder="Enter code that we texted to you"
              help="We sent you a text message. Please type it here."
              onChange={onChangeCode}
              value={this.state.smsCode} 
              required={'required'}
            />
            {error && <p className="red">
              {error}
            </p>}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button className="btn btn-primary btn-lg" type="submit">Verify Phone</Button>
            <p className="mar-top-md">
              <small>I have not received any text message yet. <br />
                <button className="link-button" onClick={this.resendSMSCode.bind(this)}>Resend code</button> to
                my number
              </small>
            </p>            
          </Col>
        </Row>
      </form>
    );
  }
}

VerifyAd.propTypes = {
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  verifyEmail: PropTypes.func.isRequired,
  verifyPhoneSendSms: PropTypes.func.isRequired,
  verifyPhoneValidateCode: PropTypes.func.isRequired,
  validEmail: PropTypes.bool,
  validPhone: PropTypes.bool,
  phoneCodeSent: PropTypes.bool,
  phone: PropTypes.string,
  error: PropTypes.string
};

VerifyAd.defaultProps = {
  validEmail: false,
  validPhone: false,
  phone: false,
  phoneCodeSent: false
}

export default VerifyAd;