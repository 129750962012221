import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Col,
  Row,
  Button,
} from "react-bootstrap";

class AdPhotos extends Component {
  handleMakePrimary(postId, photoId) {
    const { photoMakePrimary, editToken } = this.props;
    photoMakePrimary(postId, photoId, editToken);
  }

  handleDelete(postId, photoId) {
    const { photoDelete, editToken } = this.props;
    photoDelete(postId, photoId, editToken);
  }

  render() {
    const { post } = this.props;
    const { photos, post_id } = post;
    const hasPhotos = Array.isArray(photos) && photos.length > 0;
    return hasPhotos && (
      <div className="photos">
        <h3>Current Photos in the Ad</h3>
        <Grid bsClass="no-padding">
          <Row className="option-row">
            {photos.map(photo => 
              <Col xs={12} sm={4} key={photo.photo_id}>
                <div>
                  <img className="thumb" src={photo.url} alt={photo.photo_id} />
                </div>
                <div style={{ marginTop: 10, marginBottom: 20 }}>
                  <Button 
                    bsSize="small" 
                    bsStyle="default" 
                    onClick={() => this.handleMakePrimary(post_id, photo.photo_id)}>Make Primary</Button>

                  <Button 
                    bsSize="small" 
                    bsStyle="default"
                    style={{ marginLeft: 12}}
                    onClick={() => this.handleDelete(post_id, photo.photo_id)}>Delete</Button>
                </div>
              </Col>
            )}
          </Row>
        </Grid>
      </div>
    );
  }
}


AdPhotos.propTypes = {
  post: PropTypes.object,
  photoDelete: PropTypes.func.isRequired,
  photoMakePrimary: PropTypes.func.isRequired,
};

AdPhotos.defaultProps = {
  post: {}
};

export default AdPhotos;
