import { 
  FETCH_CITIES, 
  FETCH_COUNTRIES
} from '../actions/types';

const initialState = {
  cities: {},
  countries: []
}

export default function locationReducer(state = initialState, action) {
  switch(action.type) {
      case FETCH_CITIES:
        return {
          ...state,
          cities: action.payload
        };

      case FETCH_COUNTRIES:
        return {
          ...state,
          countries: action.payload
        };

      default:
        return state;
  }
}
