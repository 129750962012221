import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import _omit from 'lodash/omit';

const isValidAge = (str) => {
    return (str && parseInt(str, 10) >= 19) || str === '';
}

/*eslint no-useless-escape: "off"*/
const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const validatePhone = (phone) => {
    phone = phone || '';
    let digitsOnly = phone.replace(/[^0-9]/g, '');
    let isValid = digitsOnly && digitsOnly.length >= 10 && digitsOnly.length <= 15;
    return !!isValid;
}

const validateUrl = (url) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
}

const validateNotOwnDomain = (url) => {
    return (url.indexOf('escortz.cc') === -1)
}

class FieldGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            remainingTxt: ''
        }
    }

    validateField = (input, name) => {
        let isValid = true;
        if(this.props.type === 'email') {
            isValid = validateEmail(input) && isValid;
        }

        if(this.props.type === 'url') {
            // eslint-disable-next-line
            isValid = validateUrl(input) && validateNotOwnDomain(input) && isValid || input.length < 1;
        }

        if(this.props.type === 'phone') {
            isValid = validatePhone(input) && isValid;
        }
        
        if(this.props.numeric) {
            isValid = isValidAge(input) && isValid;
        }

        if(this.props.required) {
            isValid = !!(input && (input.length > 0) && isValid);
        }
        
        if(this.props.onValidate) {
            this.props.onValidate(isValid, name);
        }
    }

    onChange = (e) => {
        const value = e.target.value;
        const newState = { value };
        const { maxLength, showRemaining } = this.props;
        if(maxLength.length > 0 && showRemaining) {
            const remaining = parseInt(maxLength, 10) - value.length;
            newState.remainingTxt = `${remaining} characters remaining.`
        }

        this.setState(newState);
        this.props.onChange(e);
    }

    onBlur = (e) => {
        let input = e.target.value;
        let name = e.target.name;
        this.validateField(input, name);

        if(this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    render() {
        const props = _omit(this.props, ['onValidate', 'showRemaining'])
        const { value, remainingTxt } = this.state;
        return (
            <FormGroup controlId={this.props.id} validationState={this.props.clss}>
                <ControlLabel>{this.props.label}</ControlLabel>
                <FormControl {...props} onBlur={this.onBlur} onChange={this.onChange} value={value} />
                {this.props.help && <small>
                    <i className={this.props.clss}>{this.props.help}</i>
                    {remainingTxt && <span className="chars-remaining">{remainingTxt}</span>}
                </small>}
            </FormGroup>
        );
    }
}

FieldGroup.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    maxLength: PropTypes.string,
    value: PropTypes.string,
    help: PropTypes.string,
    required: PropTypes.any,
    numeric: PropTypes.any,
    clss: PropTypes.string,
    showRemaining: PropTypes.bool,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,
}

FieldGroup.defaultProps = {
    maxLength: '',
    required: '',
    numeric: '',
    clss: null,
    value: '',
    showRemaining: true,
    onValidate: () => {},
    onBlur: () => {}
}


export default FieldGroup;
