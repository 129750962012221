import _get from 'lodash/get';
import { 
  CREATE_USER,
  LOGIN_USER,
  SET_CURRENT_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  CHANGE_PASSWORD
} from './types';
const Logger = console;

const storeCurrentUser = (user, dispatch) => {
  const token = _get(user, ['data', 'token'], false);
  const email = _get(user, ['data', 'username'], false);
  const userId = _get(user, ['data', 'user_id'], false);
  if(token) {
    localStorage.setItem('session', token);
    localStorage.setItem('email', email);
    localStorage.setItem('userId', userId);
    dispatch(setCurrentUser(token, email, userId));
  }
};

export const setCurrentUser = (token, email, userId) => {
  return {
    type: SET_CURRENT_USER,
    payload: { token, email, userId }
  };
}

export const loginUser = (username, password) => (dispatch) => {
  fetch('/api/user/login', {
      method: 'POST',
      headers: {
          'content-type': 'application/json',
      },
      body: JSON.stringify({ username, password })
  })
  .then(res => res.json())
  .then((user) => {
      // logged in user
      dispatch({
          type: LOGIN_USER,
          payload: user
      });
      
      // store created user token in local storage
      storeCurrentUser(user, dispatch);
  })
  .catch((err) => {
    Logger.error('could not login', err.message);
  });
};

export const resetPassword = (username) => (dispatch) => {
  fetch('/api/user/resetpassword', {
      method: 'POST',
      headers: {
          'content-type': 'application/json',
      },
      body: JSON.stringify({ username })
  })
  .then(res => res.json())
  .then((user) => {
      // password is reset
      dispatch({
          type: RESET_PASSWORD,
          payload: user
      });
  })
  .catch((err) => {
    Logger.error('could not reset password', err.message);
  });
};

export const changePassword = (username, password, token) => (dispatch) => {
  fetch('/api/user/changepassword', {
      method: 'POST',
      headers: {
          'content-type': 'application/json',
      },
      body: JSON.stringify({ username, password, token })
  })
  .then(res => res.json())
  .then((user) => {
      // created a post
      dispatch({
          type: CHANGE_PASSWORD,
          payload: user
      });
  })
  .catch((err) => {
    Logger.error('could not change password', err.message);
  });
};

export const registerUser = (username, password, token) => (dispatch) => {
  fetch('/api/user/register', {
    method: 'POST',
    headers: {
        'content-type': 'application/json'
    },
    body: JSON.stringify({ username, password, token })
  })
  .then(res => res.json())
  .then((user) => {
    // user created action
    dispatch({
        type: CREATE_USER,
        payload: user
    });

    // store created user token in local storage
    storeCurrentUser(user, dispatch);
  })
  .catch((err) => {
    Logger.error('could not create user', err.message);
  });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('session');
  localStorage.removeItem('email');
  localStorage.removeItem('userId');
  dispatch({
    type: LOGOUT_USER
  })
}