
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { Button, Glyphicon, FormGroup, InputGroup, FormControl, Grid, Row, Col } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import Countdown from './Countdown';
import { TX_STATUS } from '../../lib/utils';

const styles = {
  address: { 
    fontSize: 18, 
    textAlign: 'center',
    marginTop: 30,
  },
  qr: {
    width: '100%',
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 30
  },
  success: {
    color: '#3c763d'
  },
  cog: {
    width: 75,
    height: 75,
    display: 'inline-table',
    marginRight: 15,
  }
};

const getFooterMsg = (status) => {
  switch(status) {
    case TX_STATUS.COMPLETE: {
      return 'Great news! Transaction is confirmed on the network.';
    }

    default: {
      return 'Your payment will be processed after it is confirmed on the Bitcoin network.';
    }
  }
}

class BitcoinQR extends Component {
  copyToClipboard(text) {
    toast(text);
  }

  gotoBlockExplorer = (address) => {
    window.open(`https://www.blockchain.com/btc/address/${address}`);
  };

  renderReceiving() {
    const { payment } = this.props;
    const { address, amountCrypto, crypto } = payment;
    return (
      <div>
        <Countdown />

        <div style={styles.qr}>
          <QRCode value={address} />
        </div>

        <FormGroup>
          <InputGroup>
            <InputGroup.Button>
              <Button>{crypto} Address</Button>
            </InputGroup.Button>
            <FormControl type="text" value={address} readOnly={true} />
            <InputGroup.Addon>
              <CopyToClipboard text={address}>
                <Glyphicon 
                  glyph="duplicate" 
                  className="pointer"
                  onClick={() => this.copyToClipboard('Address copied to clipboard')} 
                />
              </CopyToClipboard>
            </InputGroup.Addon>
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <InputGroup>
            <InputGroup.Button>
              <Button>{crypto} Amount</Button>
            </InputGroup.Button>
            <FormControl type="text" value={amountCrypto} readOnly={true} />
            <InputGroup.Addon>
              <CopyToClipboard text={amountCrypto}>
                <Glyphicon 
                  glyph="duplicate" 
                  className="pointer"
                  onClick={() => this.copyToClipboard('Amount copied to clipboard')} />
              </CopyToClipboard>
            </InputGroup.Addon>
          </InputGroup>
        </FormGroup>         
      </div>
    );
  }

  renderProcessing() {
    return (
      <div className="feedback-box">
        <h1 align="center">
          <img alt="processing..." style={styles.cog} src="/img/cog-ani.gif"/>
          <span>Processing...</span>
        </h1>
        <h3 align="center">There is nothing else that you need to do!</h3>
        <p align="center">
          We will be receiving the payment once it's confirmed on the network.
        </p>
        <p align="center">
          This can take from several minutes to several hours. If high enough
          mining fee was used it will be confirmed fairly quickly.
        </p>
      </div>
    );
  }

  renderComplete() {
    return (
      <div className="feedback-box">
        <h1 align="center" style={styles.success}><Glyphicon glyph="ok" style={styles.success} /> Complete</h1>
        <h3 align="center">This transaction is confirmed on the network!</h3>
        <p align="center">
          You can expect your ad to be published shortly.
        </p>
      </div>
    );
  }

  render() {
    const { payment } = this.props;
    const { address, amountCrypto, amountFiat, crypto, fiat, status } = payment;

    return (
      <div className="crypto-qr">
        <p>
          You may now send <b>{amountFiat} {fiat}</b> to the {crypto} address below. Please note that it can take some time 
          for a transaction to get validated on the network. The time for this validation depends on
          the miner fee that you use in a transaction (higher miner fee means faster transactions)
        </p>

        <Grid className="crypto-amounts">
          <Row className="line">
            <Col xs={12} sm={6}>Amount</Col>
            <Col xs={12} sm={6}>{fiat} {amountFiat}</Col>
          </Row>
        </Grid>
        <Grid className="crypto-amounts">
          <Row className="line">
            <Col xs={12} sm={6}>{crypto} Amount</Col>
            <Col xs={12} sm={6}>{crypto} {amountCrypto}</Col>
          </Row>
        </Grid>
        
        <Grid className="crypto-network">
          <Row className="line">
            <Col xs={12} sm={6}>Selected Network</Col>
            <Col xs={12} sm={6} style={{ textAlign: 'right' }}>Bitcoin</Col>
          </Row>
        </Grid>

        {status === TX_STATUS.ASSIGNED && this.renderReceiving()}
        {status === TX_STATUS.PROCESSING && this.renderProcessing()}
        {status === TX_STATUS.COMPLETE && this.renderComplete()}
  
        <div>
          <p align="center">
            {getFooterMsg(status)}
          </p>
          <p align="center">
            <Button className="btn btn-default" onClick={() => this.gotoBlockExplorer(address)}>Check on Bitcoin Network</Button>
          </p>
        </div>
      </div>
    );
  }
}

BitcoinQR.propTypes = {
  payment: PropTypes.object,
};

BitcoinQR.defaultProps = {
  payment: {}
}

export default BitcoinQR;



