import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get'
import { Navbar, NavItem, Nav, Grid, Row, Col } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import './App.css';

import { Provider } from 'react-redux';
import store from './store';

class App extends Component {
  static defaultProps = {
    content: { 
      heading: {}
    }
  }

  constructor(props) {
    super(props)
    this.logoutUser = this.logoutUser.bind(this);
  }

  componentDidMount() {
    this.props.loadConfig()
  }

  logoutUser() {
    this.props.logoutUser();
    window.location = '/';
  }

  renderUnauthItems() {
    return (
      <Navbar.Collapse>
        <Nav pullRight>
          <Nav>
            <NavItem href="/">Post New Ad</NavItem>
          </Nav>
          {/* <LinkContainer to="/login">
            <NavItem href="/login">
              Login
            </NavItem>
          </LinkContainer> */}
        </Nav>
      </Navbar.Collapse>
    );
  }

  renderAuthItems() {
    return (
      <Navbar.Collapse>
        <Nav>
          <NavItem href="/">New Post</NavItem>
          {/* <LinkContainer to="/login">
            <NavItem href="/posts">My Posts</NavItem>
          </LinkContainer> */}
        </Nav>
        <Nav pullRight>
          <NavItem onClick={() => { this.logoutUser(); }}>
          Logout
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    );
  }
  
  render() {
    const { isAuth, siteUrl, content: { heading } } = this.props
    const part1 = _get(heading, 'part1', '')
    const part2 = _get(heading, 'part2', '')
    const part3 = _get(heading, 'part3', '')
    return (
      <Provider store={store}>
        <div className="App">
          <Navbar inverse collapseOnSelect>
            <Navbar.Header>
              <Navbar.Brand>
                <a href={siteUrl}><span className="sm">{part1}</span>{part2}<span className="sm">{part3}</span></a>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            {isAuth && this.renderAuthItems()}
            {!isAuth && this.renderUnauthItems()}
          </Navbar>

          <Grid>
            <Row className="show-grid">
              <Col xs={12} md={9}>
                {this.props.children}
              </Col>
            </Row>
          </Grid>  
          <Toaster position="bottom-center" toastOptions={{
            style: {
              background: '#363636',
              color: '#fff',
            }
          }} />
        </div>
      </Provider>
    );
  }
}

export default App;

App.propTypes = {
  loadConfig: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  siteUrl: PropTypes.string
};
