import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import Spinner from '../Spinner/Spinner';
import Config from '../../lib/config';

class Posts extends Component {
    constructor(props) {
        super(props);
        this.getViewLink = this.getViewLink.bind(this);
        this.getEditLink = this.getEditLink.bind(this);
    }

    componentWillMount() {
        this.props.fetchPosts();
    }

    getViewLink(postId, city) {
        const conf = new Config().getConfig();
        const { SITE_PROTOCOL, SITE_NAME } = conf;
        return `${SITE_PROTOCOL}${city}.${SITE_NAME}/ads/details/${postId}`;
    }

    getEditLink(postId) {
        return `/?id=${postId}&edit=auth`;
    }

    renderPosts() {
        const { posts, loading } = this.props;
        if(loading) {
            return <Spinner />
        }

        if(!posts || posts.length < 1) {
            return <p>You have no posts at the moment.</p>
        }

        const listItems = posts.map((post) => {
            const { postId, city, title, location, posted, approved, status, expiresIn } = post;
            const rowClass = approved ? 'active' : 'inactive';
            const active = approved 
                ? <span className="green">{status}</span>
                : <span className="red">{status}</span>
            return (
                <tr className={rowClass} key={postId}>
                    <td align="center">{active}</td>
                    <td className="manage" align="center">
                        <a className="action" href={this.getViewLink(postId, city)} target="_blank" rel="noreferrer">view</a>
                        <a className="action" href={this.getEditLink(postId)}>edit</a>
                    </td>
                    <td>{title}</td>
                    <td>{location}</td>
                    <td>{posted}</td>
                    <td>{expiresIn}</td>
                </tr>
            );
        });

        return (
            <div>
                <p>Showing your most recent posts</p>
                <Table className="list" striped bordered condensed hover>
                    <thead>
                        <tr>
                            <td align="center">Status</td>
                            <td align="center">Manage</td>
                            <td>Posting Title</td>
                            <td>City</td>
                            <td>Posted Date</td>
                            <td>Expiry</td>
                        </tr>
                    </thead>
                    <tbody>
                        {listItems}
                    </tbody>
                </Table>
            </div>
        );


    }

    render() {
        return (
            <div>
                <h1>My Posts</h1>
                {this.renderPosts()}
            </div>
        )
    }
}

Posts.propTypes = {
    fetchPosts: PropTypes.func.isRequired,
    posts: PropTypes.array,
    loading: PropTypes.bool
};

Posts.defaultProps = {
    posts: [],
    loading: false
};

export default Posts;
