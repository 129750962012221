import * as types from '../actions/types';
import * as views from '../components/AdPost/PostViewsDef';
import _get from 'lodash/get'
import _deepClone from 'lodash/cloneDeep';
import { TX_STATUS } from '../lib/utils';

const initialState = {
    post: false,
    loading: false,
    validation: { phone: null, backlink: null, email: null },
    view: views.POST_RULES,
    steps: {},
    payment: {
        address: null,
        amount: null,
        crypto: null,
        fiat: null,
        status: TX_STATUS.PENDING
    },
    token: null,
};

const enableSteps = (newSteps, currState) => {
    const steps = _deepClone(currState.steps) || [];

    if(Array.isArray(newSteps)) {
        newSteps.forEach(step => {
            steps[step] = 1;
        });
    }

    return steps; 
};

export default function postReducer(state = initialState, action) {
    let newState;
    switch(action.type) {
        case types.FETCH_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }

        case types.NEW_POST: {
            const isPosted = !!_get(action, ['payload', 'ok'], false);
            const nextView = isPosted ? views.PHOTO_UPLOAD : views.POST_ERROR;
            const post = _get(action, ['payload', 'data']);
            const token = _get(action, ['payload', 'token'])
            return {
                ...state,
                post,
                view: nextView,
                loading: false,
                token,
            };
        }
        
        case types.UPDATE_POST_SUCCESS: {
            const isUpdated = !!_get(action, ['payload', 'ok'], false);
            const post = _get(action, ['payload', 'data'], {});
            const nextView = isUpdated ? views.PHOTO_UPLOAD : views.UPDATE_ERROR;
            return {
                ...state,
                post,
                loading: false,
                view: nextView
            };
        }


        case types.DELETE_POST: {
            const isDeleted = !!_get(action, ['payload', 'ok'], false);
            const nextView = isDeleted ? views.DELETE_SUCCESS : views.UPDATE_ERROR;
            return {
                ...state,
                post: false,
                view: nextView
            };
        }

        case types.FETCH_POST_SUCCESS: {
            const post = _get(action, ['payload', 'data'], {})
            return {
                ...state,
                post,
                loading: false,
                view: views.EDIT_AD
            };
        }

        // we could easily grab `verification.email` to see whether user is
        // verified or not. however, we don't want to pull entire post.
        case types.VERIFY_EMAIL: {
            const data = _get(action, ['payload', 'data'], {});
            return {
                ...state,
                loading: false,
                post: { ...data }
            }
        }

        case types.VERIFY_PHONE_SEND_SMS: {
            return {
                ...state,
                loading: false
            };
        }

        case types.VERIFY_PHONE_VALIDATE: {
            const data = _get(action, ['payload', 'data'], {});
            return {
                ...state,
                loading: false,
                post: { ...data }
            }
        }

        case types.VALIDATE_AD: {
            let obj = JSON.parse(JSON.stringify(state.validation));

            if(action.payload.validation.hasOwnProperty('phone')) {
                obj.phone = action.payload.validation.phone;
            }

            if(action.payload.validation.hasOwnProperty('backlink')) {
                obj.backlink = action.payload.validation.backlink;
            }

            newState = {
                ...state,
                validation: obj
            };

            return newState;
        }

        case types.UPLOAD_PHOTOS: {
            const post = _deepClone(state.post);
            post.photos = _get(action, ['payload', 'photos'], []);
            return {
                ...state,
                post,
                updatedPhotos: post.photos // @todo: why do we need this?
            };
        }

        case types.PHOTO_PRIMARY:
        case types.PHOTO_DELETE: {
            const post = _deepClone(state.post);
            post.photos = _get(action, ['payload', 'photos'], []);
            return {
                ...state,
                post,
                updatedPhotos: post.photos // @todo: why do we need this?
            };
        }

        case types.GENERATE_ADDRESS: {
            const payment = _deepClone(state.payment);
            const { payload: { data } } = action;
            const { address, crypto, fiat, amountFiat, amountCrypto } = data;
            payment.address = address;
            payment.amountFiat = amountFiat;
            payment.amountCrypto = amountCrypto;
            payment.fiat = fiat;
            payment.crypto = crypto;
            payment.status = TX_STATUS.ASSIGNED;
            return {
                ...state,
                payment,
            };
        }

        case types.CHECK_PAYMENT_STATUS: {
            const payment = _deepClone(state.payment);
            const { payload: { data } } = action;
            const { status } = data;
            payment.status = status;
            return {
                ...state,
                payment,
            };
        }

        case types.ENABLE_STEPS: {
            return {
                ...state,
                steps: enableSteps(action.payload, state),
            }
        }

        case types.SET_VIEW: {
            return {
                ...state,
                view: action.payload
            }
        }

        default:
            return state;
    }
}
