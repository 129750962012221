import _get from 'lodash/get';
export const getDetailPageUrl = (post, env, postId) => {
  const id = _get(post, 'post_id', false) || postId;
  const city = _get(post, 'city', false);
  const domain = _get(env, 'SITE_NAME', '');
  const protocol = _get(env, 'SITE_PROTOCOL', 'https://');
  return `${protocol}${city}.${domain}/ads/details/${id}`;
};

export const getCityUrl = (city, env) => {
  const domain = _get(env, 'SITE_NAME', '');
  const protocol = _get(env, 'SITE_PROTOCOL', 'https://');
  return `${protocol}${city}.${domain}/ads/`;
};

export const TX_STATUS = {
  PENDING: 'pending',
  ASSIGNED: 'assigned',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
};

export const gaEnabled = () => {
  return window.ga && window.location.hostname !== "hostname";
};
